import * as microsoftTeams from '@microsoft/teams-js';
import * as Sentry from '@sentry/react';
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { getCurrentUser, User } from 'services/user-service';
import { getTeamsContext, isTeams, teamsOAuthAuthentication } from 'utils/teams';

export interface UserContextProps {
  inTeams: boolean;
  teamsContext: microsoftTeams.app.Context | null;
  user: User | undefined;
  loading: boolean;
  needsExpandedPermissions: boolean;
  loadUser: () => void;
  reloadUser: () => void;
  teamsAuth: () => void;
  updateNeedsExpandedPermissions: (newValue: boolean) => void;
}

export const UserContext = React.createContext<UserContextProps>({} as UserContextProps);

export function UserProvider({ children }: PropsWithChildren<Record<symbol, symbol>>) {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User>();
  const [inTeams, setInTeams] = useState<boolean>(false);
  const [teamsContext, setTeamsContext] = useState<microsoftTeams.app.Context | null>(null);
  const [needsExpandedPermissions, setNeedsExpandedPermissions] = useState<boolean>(false);

  const loadUser = useCallback(() => {
    const load = async () => {
      try {
        const context = await getTeamsContext();
        setTeamsContext(context);

        const runningTeams = await isTeams();
        setInTeams(runningTeams);

        if (runningTeams) {
          microsoftTeams.app.notifySuccess();
        }

        let user;
        if (!runningTeams) {
          user = await getCurrentUser();
        }
        if (user) {
          setUser(user);
        }

        setLoading(false);
      } catch (err) {
        Sentry.captureException(err);
        setLoading(false);
      }
    };
    load();
  }, []);

  const reloadUser = useCallback(() => {
    const load = async () => {
      try {
        const user = await getCurrentUser();
        if (user) {
          setUser(user);
        }
      } catch (err) {
        Sentry.captureException(err);
      }
    };
    load();
  }, []);

  const teamsAuth = useCallback(() => {
    const triggerAuth = async () => {
      if (!needsExpandedPermissions) {
        setLoading(true);
      }
      try {
        const user = await teamsOAuthAuthentication(needsExpandedPermissions);
        if (user) {
          setNeedsExpandedPermissions(false);
          setUser(user);
          setLoading(false);
        }
      } catch (err) {
        setNeedsExpandedPermissions(false);
        setLoading(false);
        Sentry.captureException(err);
      }
    };
    triggerAuth();
  }, [needsExpandedPermissions, setNeedsExpandedPermissions]);

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  const updateNeedsExpandedPermissions = useCallback(
    (newValue: boolean) => {
      setNeedsExpandedPermissions(newValue);
    },
    [setNeedsExpandedPermissions]
  );

  const contextValue = useMemo(
    () => ({
      inTeams,
      loading,
      teamsContext,
      user,
      needsExpandedPermissions,
      loadUser,
      reloadUser,
      teamsAuth,
      updateNeedsExpandedPermissions,
    }),
    [
      inTeams,
      loading,
      teamsContext,
      user,
      needsExpandedPermissions,
      loadUser,
      reloadUser,
      teamsAuth,
      updateNeedsExpandedPermissions,
    ]
  );

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
}
