import { Footer } from 'components/footer';
import { useContext, useEffect, useState } from 'react';
import { getLoginContent, LoginContent } from 'services/content-service';
import { CSSTransition } from 'react-transition-group';
import Cookies from 'universal-cookie';
import EmailLogin from 'containers/login-page/email';
import SsoLogin from 'containers/login-page/sso';
import { ThemeContext } from 'contexts/theme';

interface LoginPageProps {
  authenticityToken?: string;
  sso?: boolean;
  invalidLink?: boolean;
}

function LoginPage({ authenticityToken, sso, invalidLink = false }: LoginPageProps): JSX.Element {
  const cookies = new Cookies();

  // toggle this value to enable sso-based login
  const ssoEnabled = window.location.search.includes('sso') || sso;
  const errorCookie = cookies.get('error');
  const isLinkInvalid = errorCookie === 'expired' || invalidLink;
  const [loginContent, setLoginContent] = useState<LoginContent>();
  const { currentTheme, currentEpisode } = useContext(ThemeContext);
  const [backgroundImage, setBackgroundImage] = useState<string | undefined>(undefined);

  useEffect(() => {
    getLoginContent().then((res) => {
      setLoginContent(res);
    });
  }, []);

  useEffect(() => {
    setBackgroundImage(currentTheme?.episodes[currentEpisode].video.image_url);
  }, [currentTheme, currentEpisode]);

  return (
    <div
      className="flex flex-col h-screen min-h-screen bg-cover bg-center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div className="flex flex-col grow w-full max-w-1440 mx-auto mb-28 desktop:pt-44 justify-center">
        <CSSTransition timeout={1000} classNames="fade" in={!!loginContent} mountOnEnter unmountOnExit>
          {ssoEnabled ? (
            <SsoLogin authenticityToken={authenticityToken} />
          ) : loginContent ? (
            <EmailLogin content={loginContent} isLinkInvalid={isLinkInvalid} />
          ) : (
            <></>
          )}
        </CSSTransition>
      </div>
      <Footer />
    </div>
  );
}

export default LoginPage;
