import { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ThemeContext } from 'contexts/theme';
import { LoadingSpinner } from 'components/loaders/LoadingSpinner';
import VideoPlayPage from 'containers/video-play-page';

function TeamsAppContainer(): JSX.Element {
  const { loading } = useContext(ThemeContext);

  // prettier-ignore
  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Routes>
          <Route path="*" element={<VideoPlayPage />} />
        </Routes>
      )}
    </>
  );
}

export default TeamsAppContainer;
