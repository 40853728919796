import { createRoot } from 'react-dom/client';
import { Integration } from '@sentry/types';
import { BrowserTracing } from '@sentry/browser';
import * as Sentry from '@sentry/react';
import './styles/app.scss';
import App from './App';
import './i18n';
import { ErrorFallback } from 'components/error-fallback';
import { JourneyProvider } from 'contexts/journey';
import { UserProvider } from 'contexts/user';
import { ThemeProvider } from 'contexts/theme';
import { WelcomeContentProvider } from 'contexts/welcome';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing() as Integration],
    tracesSampleRate: 0.2,
  });
}

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Sentry.ErrorBoundary fallback={<ErrorFallback heading="errorPage.heading" body="errorPage.body" />}>
    <div id="app">
      <WelcomeContentProvider>
        <UserProvider>
          <ThemeProvider>
            <JourneyProvider>
              <App />
            </JourneyProvider>
          </ThemeProvider>
        </UserProvider>
      </WelcomeContentProvider>
    </div>
  </Sentry.ErrorBoundary>
);
